import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { cookies } from "./../../hooks/useCookies";
import SignPages from './../../components/SignPages/index';
import iconLogin from "../../assets/images/icons/login.png";

export default function Login() {
  
  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: submit
  });
  
  const { Login, loading, setLoading } = useAuth();
  
  async function submit() {
    setLoading(true);
    await Login(values);
  }

  useEffect(() => {
    let toastVerify = cookies.get("@Safysign:verify-toast");
    let toastVerifyCompany = cookies.get('@Safysign:verify-toast-company')
    let toastVerifyForgotPass= cookies.get("@Safysign:verify-forgot-pass-toast")
    let toastVerifyChangePassword= cookies.get("@Safysign:toastForChangePassword")

    if (toastVerify === "true") {
      toast.success("Usuário cadastrado com sucesso, verifique seu email!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      cookies.remove("@Safysign:verify-toast");
    }
    if (toastVerifyCompany === "true"){
      toast.success("Usuário e empresa cadastrados com sucesso", {
        position: toast.POSITION.TOP_RIGHT,
      });
      cookies.remove("@Safysign:verify-toast-company");
    }
    if (toastVerifyForgotPass === "true"){
      toast.success("E-mail para realizar o reset da senha enviado", {
        position: toast.POSITION.TOP_RIGHT,
      });
      cookies.remove("@Safysign:verify-forgot-pass-toast");
    }
    if (toastVerifyChangePassword === "true"){
      toast.success("Senha alterada com sucesso", {
        position: toast.POSITION.TOP_RIGHT,
      });
      cookies.remove("@Safysign:toastForChangePassword");
    }

  }, []);

  return (
      <form onSubmit={handleSubmit}>
        <SignPages
          firstIcon={iconLogin}
          altFirstInput="Icone de Login"
          firstType="text"
          firstPlace="E-mail"
          firstName="username"
          handleChange={handleChange}
          altSecondInput="Icone de senha"
          secondType="password"
          secondPlace="Senha"
          secondName="password"
          firstInput={values.username}
          secondInput={values.password}
          isLogin={true}
          link="/new-account"
          childLink="Cadastrar"
          loading={loading}
          disabled={loading}
          textButton="Entrar"
        >
        </SignPages>
      </form>
  );
}
