import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as api from "../../../services/api";
import { Auth } from "../../../layouts/auth";
import { useAuth } from './../../../hooks/useAuth';
import { BsArrowReturnLeft } from "react-icons/bs";
import SignPages from "../../../components/SignPages";
import iconPassword from "../../../assets/images/icons/senha.png";
import { useNavigate } from 'react-router-dom';
import { cookies } from "../../../hooks/useCookies";

export default function ResetPassword() {
  const [loading, setLoading] = useState();
  const { user, setUser } = useAuth()
  const navigate = useNavigate()

  async function submit() {
    let password = {password: values.password}

    setLoading(true);
    if (values.confirmPassword !== values.password) {
      setLoading(false)
      return toast.error("As senhas não correspondem", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      api.put(`/user/change_password/${user.id}/`, password)
        .then((response) => {
          if(response.status === 200){
            setUser(null)
            cookies.remove("@Safysign:user");
            cookies.remove("@Safysign:access");
            cookies.remove("@Safysign:refresh");
            cookies.set("@Safysign:toastForChangePassword", true)
            
            navigate("/", { replace: true })
          } else if (response.response.status === 400) {
            toast.error("Senha invalida, a senha deverá conter no mínimo 8 caracteres, sendo eles 1 letra maiuscula, 1 letra minuscula, 1 simbolo e 1 numero", {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            toast.error("Ocorreu um erro, tente novamente mais tarde", {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        })
        .catch(() => {
          toast.error("Ocorreu um erro, tente novamente mais tarde", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        
    }
    setLoading(false);
  }

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: submit,
  });

  return (
    <Auth>
      <form onSubmit={handleSubmit}>
        <SignPages
          altFirstInput="Icone de Senha"
          handleChange={handleChange}
          firstInput={values.password}
          altSecondInput="Icone de Senha"
          secondInput={values.confirmPassword}
          isLogin={false}
          link={"../"}
          childLink={<BsArrowReturnLeft size={30} />}
          loading={loading}
          disabled={loading}
          textButton="Confirmar"
          text="Definir senha"
          firstPlace="Senha"
          secondPlace="Confirmar Senha"
          firstIcon={iconPassword}
          firstType="password"
          secondType="password"
          firstName="password"
          secondName="confirmPassword"
        ></SignPages>
      </form>
    </Auth>
  );
}
