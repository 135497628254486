/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useFormik } from 'formik';
import SafeButton from "../SafeButton";
import { toast } from 'react-toastify';
import * as api from "../../services/api";
import Input from "../../components/Input";
import { FadeLoader } from "react-spinners";
import { useAuth } from './../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Link, useParams} from "react-router-dom";
import {SelectPicker,RadioGroup,Radio,} from "rsuite";
import InputMask from "../../components/Input/InputMask";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Form, Title, Container, Row, InputContainer } from "./styles";
import { styled, Switch } from '@mui/material';
import { colors } from '../../styles/colors';

export default function ScreensUser({changeFlag}) {

    const [planoID, setPlanoID] = useState(null)
    const [planData, setPlanData] = useState([])
    const [clientID, setClientID] = useState(null)
    const [getsDone, setGetsDone] = useState(false)
    const [clientData, setClientData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [isDisabled, setIsDisabled] = useState(false)
    const [isSelected, setIsSelected] = useState({
        plano: false,
        client: false
    })
    const [defaultValueToggle, setDefaultValueToggle] = useState()
    const [ isBankSlipPayer, setIsBankSlipPayer] = useState(false)

    const navigate = useNavigate()

    const { user } = useAuth()
    const { id } = useParams();
    
    useLayoutEffect(() => {

            setIsDisabled(true)
            async function allGets() {
            setIsLoading(true)
            await api.get('/company/list/all/')
                .then((response) => {
                    let data = [{label:'Nenhum',value:'Nenhum'}]
                    response.data.detail.map(item => {
                        data = [...data, {label:item.social_reason,value:item.id}]
                    })
                    setClientData(data)
                    setIsDisabled(false)
                })
                .catch(() => {
                    toast.error('Ocorreu um erro ao resgatar as informações deste usuários', )
                })

            await api.get('/plan/list/all/')
                .then((response) => {
                    let data = [{label:'Nenhum',value:'Nenhum'}]
                    response.data.detail.map(item => {
                        data = [...data, {label:item.name,value:item.id}]
                    })
                    setPlanData(data)
                    setIsDisabled(false)
                })
                .catch(() => {
                    toast.error('Ocorreu um erro ao resgatar as informações deste usuários', )
                })

            if(changeFlag === true) {
                await api.get(`/user/retrieve/${id}/`)
                    .then((response) => {
                        if(response.status === 200) {
                            setCurrentUser(response.data.detail)
                            if(response?.data?.detail?.company !== null) setIsSelected({...isSelected, client:true})
                            changeFlag === true ?
                            response.data.detail.profile === '3'
                                ? setDefaultValueToggle('is_sysadmin') 
                                : response.data.detail.profile === '2'
                                        ? setDefaultValueToggle('is_companyStaffUser')
                                        : response.data.detail.profile === '1'
                                            ? setDefaultValueToggle('user')
                                            : setDefaultValueToggle('')
                            : setDefaultValueToggle('')
                            if (response.data.detail.is_bank_slip_payer !== undefined) {
                                setIsBankSlipPayer(response.data.detail.is_bank_slip_payer); 
                            }
                        } else {
                            toast.error('Ocorreu um erro ao resgatar as informações deste usuários', )
                        }
                    })
                    .catch(() => {
                        toast.error('Ocorreu um erro ao resgatar as informações deste usuários', )
                    })
                }
        }

        

            allGets()
                .then(() => {setGetsDone(true);setIsLoading(false)}
        )
    }

    ,[])
    

    useEffect(() => {

        if(changeFlag === true) {
                if(planData.length > 0 ){
                planData.forEach(item => {
                    if(item.value === currentUser.plan) {
                        setPlanoID(item.value)
                    }
                })
            }

            if(clientData.length > 0){
                clientData.forEach(item => {
                    if(item.value === currentUser.company) setClientID(item.value)
                })
            }
        }

    }, [getsDone])

    let initialValuesForCreate = {
        firstName:'',
        lastName:'',
        email:'',
        cpf:'',
        age:'',
        phone:'',
        plan:'',
        company:'',
        profile:''
    }

    let initialValuesForChange = {
        firstName: currentUser.first_name,
        lastName:currentUser.last_name,
        email: currentUser.email,
        cpf: currentUser.cpf,
        age: currentUser.age,
        phone: currentUser.phone,
        plan: currentUser.plan,
        company: currentUser.company,
        profile: ''
    }

    function submitChange(mode) {
        let i = {
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            email: currentUser.email,
            cpf: currentUser.cpf,
            age: currentUser.age,
            phone: currentUser.phone,
            plan: currentUser.plan,
            company: currentUser.company,
            profile: currentUser.profile,
            is_bank_slip_payer: isBankSlipPayer
        }
        let f = {
            first_name:'',
            last_name:'',
            email:'',
            cpf:'',
            age:'',
            phone:'',
            plan:'',
            company:'',
            profile:'',
            is_bank_slip_payer: isBankSlipPayer
        }
        let convertProfile = 
        defaultValueToggle === 'is_sysadmin' 
            ? '3' 
                : defaultValueToggle === 'is_companyStaffUser' 
                ? '2' 
                    : '1'

        i.first_name === values.firstName || values.firstName === null || values.firstName === undefined 
            ? f.first_name = i.first_name 
            : f.first_name = values.firstName
        i.last_name === values.lastName || values.lastName === null || values.lastName === undefined 
            ? f.last_name = i.last_name 
            : f.last_name = values.lastName
        i.email === values.email || values.email === null || values.email === undefined 
            ? f.email = i.email 
            : f.email = values.email
        i.cpf === values.cpf || values.cpf === null || values.cpf === undefined 
            ? f.cpf = i.cpf 
            : f.cpf = values.cpf
        i.age === values.age || values.age === null || values.age === undefined 
            ? f.age = i.age 
            : f.age = values.age
        i.phone === values.phone || values.phone === null || values.phone === undefined 
            ? f.phone = i.phone 
            : f.phone = values.phone
        i.plan === planoID 
            ? f.plan = planoID 
            : i.plan === null && (planoID === "" || planoID === null) 
                ? f.plan = null 
                : i.plan !== planoID && planoID !== null && planoID !== "" 
                    ? f.plan = planoID 
                    : f.plan = null
        i.company === clientID 
            ? f.company = clientID 
            : i.company === null && (clientID === "" || clientID === null) 
                ? f.company = null 
                : i.company !== clientID && clientID !== null && clientID !== "" 
                    ? f.company = clientID 
                    : f.company = null
        i.profile === convertProfile 
            ? f.profile = convertProfile 
            : f.profile = convertProfile

        if(mode === true) {
            setIsLoading(true)
            api.put(`/user/update/${id}/`, f, "application/json")
            .then((response) => {
                if (response.response?.status === 400){
                    setIsLoading(false)
                    toast.error('Já existe usuário com este e-mail cadastrado',{
                        position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.success('Usuário alterado com sucesso',{
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false)
                    navigate('/users',{replace:true})
                }
            })
            .catch(erro => {
                setIsLoading(false)
                toast.error(erro.data.detail, {position: toast.POSITION.TOP_RIGHT})
            })
        } else {
            setIsLoading(true)
            api.post('/user/create/', f, "application/json")
                .then((response) => {
                    if (response.response?.status === 400){
                        setIsLoading(false)
                        toast.error('Já existe usuário com este e-mail cadastrado',{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    } else {
                        toast.success('Usuário cadastrado com sucesso',{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    setIsLoading(false)
                    navigate('/users',{replace:true})
                }
                })
                .catch(erro => {
                    setIsLoading(false)
                    toast.error(erro.data.detail, {position: toast.POSITION.TOP_RIGHT})
                })
        }
    }

    const { handleChange, handleSubmit, values } = useFormik({
        initialValues: changeFlag ? initialValuesForChange : initialValuesForCreate,
        onSubmit: () => {submitChange(changeFlag)},
    });

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: `${colors.primary}`,
              opacity: 1,
              border: 0,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#E9E9EA',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

      function hancleCheckBox (e) {
        setIsBankSlipPayer(e.target.checked)
    }

    return (
        <>
            <Title>
                <p>
                    {changeFlag
                        ? "Alterar Cadastro de Usuario"
                        : "Cadastrar Usuario"}
                </p>
                <Link to="/users">
                    <SafeButton variant="primary">Voltar</SafeButton>
                </Link>
            </Title>
            {isLoading && changeFlag ? (
                <FadeLoader color="#60159a" />
            ) : (
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <InputContainer className="half-container">
                                <p>Nome:</p>
                                <Input
                                    name="firstName"
                                    placeholder="Primeiro Nome"
                                    defaultValue={currentUser.first_name}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer className='half-container'>
                                <p>Sobrenome</p>
                                <Input
                                    name="lastName"
                                    placeholder="Primeiro Nome"
                                    defaultValue={currentUser.last_name}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer className="terciary-container">
                                <p>E-mail:</p>
                                <Input
                                    name="email"
                                    placeholder="Email"
                                    defaultValue={currentUser.email}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <p>CPF: </p>
                                <InputMask
                                    required
                                    name="cpf"
                                    maskChar=""
                                    minLength={'11'}
                                    placeholder="CPF"
                                    mask="999.999.999-99"
                                    onChange={handleChange}
                                    defaultValue={currentUser.cpf}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label htmlFor="datePicker">Data de Nascimento: </label>
                                <input
                                    required
                                    type="date"
                                    defaultValue={currentUser.age}
                                    maxLength={8}
                                    name="age"
                                    placeholder="Data de nascimento"
                                    className="input-date"
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <p>Celular: </p>
                                <InputMask
                                    mask="(99) 9 9999-9999"
                                    maskChar=""
                                    name="phone"
                                    placeholder="Celular"
                                    defaultValue={currentUser.phone}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <label>Pagar via boleto</label>
                                <IOSSwitch
                                    checked={isBankSlipPayer}
                                    onChange={hancleCheckBox}
                                />
                            </div>
                            {user.profile === '3' ? (
                                <div style={{display: isSelected?.plano === true ? 'none' : 'block'}}>
                                    <InputContainer className={"small-container"}>
                                        <label htmlFor="clientePicker">Cliente: </label>
                                        <SelectPicker
                                            preventOverflow
                                            name="company"
                                            style={{ width: "100%" }}
                                            data={clientData}
                                            onSelect={(value) => {
                                                setClientID(value);
                                                if (value !== 'Nenhum') setIsSelected({...isSelected, client:true})
                                                setPlanoID(null)
                                            }}
                                            onClean={() => {
                                                setClientID(null)
                                                setIsSelected({...isSelected, client:false})
                                                setDefaultValueToggle('user')
                                            }}
                                            id="clientePicker"
                                            value={clientID}
                                        />
                                    </InputContainer>
                                </div>
                            ) : null}

                            {user.profile === '3' || changeFlag === false ? (
                                <div style={{display: isSelected?.client === true ? 'none' : 'block'}}>
                                    <InputContainer className={isSelected?.plano === true ? "small-container" : "half-container"}>
                                        <label htmlFor="planoPicker">Plano: </label>
                                        <SelectPicker
                                            preventOverflow
                                            name='plan'
                                            defaultValue={planoID}
                                            style={{ width: '100%' }}
                                            data={planData}
                                            onSelect={value => {
                                                setPlanoID(value)
                                                if (value !== 'Nenhum') setIsSelected({...isSelected, plano:true})
                                                setClientID(null)
                                            }}
                                            id="planoPicker"
                                            value={planoID}
                                            onClean={() => {
                                                setPlanoID(null)
                                                setIsSelected({...isSelected, plano:false})
                                            }}
                                        />
                                    </InputContainer>
                                </div>
                            ) : null}

                            {changeFlag ?<div style={{height:"62.85px", display:'flex', width: '250px', flexDirection:'column'}}>
                                <p>Saldo: </p>
                                <div style={{color:'white', height: '40px', backgroundColor:'#616161', display:'flex', alignItems:'center', justifyContent:'center', width:'100%', borderRadius:'8px'}}>
                                    {currentUser?.user_balance?.balance}
                                </div>
                            </div>: <></>}

                            <div className="typeUser" style={{width: 538.1}}>
                                <label
                                    htmlFor="ordenado"
                                >
                                    Tipo de usuário:{" "}
                                </label>
                                <RadioGroup
                                    name='profile'
                                    value={defaultValueToggle}
                                    onChange={(value) => {
                                        setDefaultValueToggle(value)
                                        handleChange(value)
                                    }}
                                >
                                    {user.profile === '3' ? (
                                    <Radio value="is_sysadmin">Administrador de sistema</Radio>
                                    ) : null}
                                    <Radio style={{display: isSelected.client === true ? 'block' : 'none'}} value="is_companyStaffUser">Representante da empresa</Radio>
                                    <Radio value="user">Usuário</Radio>
                                </RadioGroup>
                            </div>
                        </Row>
                        <div className="buttonsContainer">
                            <Link to="/users" style={{ textDecoration: "none" }}>
                                <SafeButton variant="secondary">Cancelar</SafeButton>
                            </Link>
                            <SafeButton disabled={isDisabled} variant="primary" type="submit" loading={isLoading}>
                                {changeFlag ? "Alterar Cadastro" : "Cadastrar"}
                            </SafeButton>
                        </div>
                    </Form>
                </Container>
            )}
        </>
    );
}