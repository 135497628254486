/* eslint-disable react-hooks/exhaustive-deps */
import { SelectPicker } from 'rsuite';
import { toast } from 'react-toastify';
import * as api from '../../services/api';
import Input from '../../components/Input';
import SafeButton from './../SafeButton/index';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import React, { useState, useLayoutEffect } from 'react';
import useWindowSize2 from './../../hooks/useWindowSize2';
import { InputMaskNormal } from '../../components/Input/InputMask';
import { Form, Title, Container, Row, InputContainer } from './styles';
import { styled, Switch } from '@mui/material';
import { colors } from '../../styles/colors';

export default function ClientScreen({mode=false}) {
    
    const [planoID, setPlanoID] = useState(0);

    const [cepData, setCepData] = useState({
        bairro: '',
        cep: '',
        complemento: '',
        ddd: '',
        gia: '',
        ibge: '',
        localidade: '',
        logradouro: '',
        uf: ''
    });

  const [planoData, setPlanoData] = useState([]);
  const [cepCompany, setCepCompany] = useState();
  const [telCompany, setTelCompany] = useState();
  const [companyData, setCompanyData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [cnpjCompany, setCnpjCompany] = useState("");
  const [isBankSlipPayer, setIsBankSlipPayer] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const width = useWindowSize2();

  useLayoutEffect(() => {
    api
      .get("/plan/list/all/?category=2")
      .then((response) => {
        let plans = [];
        if(response?.status === 200) {
          response?.data.detail.forEach((item) =>{
            plans = [...plans, {label:item.name, value:item.id}]
          })
          setPlanoData(plans)
        } else {
            toast.error('Ocorreu um erro ao resgatar informações dos planos, favor, tente novamente mais tarde', {
              position: toast.POSITION.TOP_RIGHT
            })
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao resgatar informações dos planos, favor, tente novamente mais tarde', {
            position: toast.POSITION.TOP_RIGHT
        })
    })

    if (mode === true) {
      api
        .get(`/company/retrieve/${id}/`)
        .then((response) => {
          if(response?.status === 200) {
            setCnpjCompany(response?.data.detail.cnpj)
            setCepCompany(response?.data.detail.address.cep)
            setCompanyData(response?.data.detail)
            setTelCompany(response?.data.detail.number)
            setPlanoID(response?.data.detail.plan)
            setCepData(response?.data.detail.address)
            if (response.data.detail.is_bank_slip_payer !== undefined) {
              setIsBankSlipPayer(response.data.detail.is_bank_slip_payer);
            }
          } else {
            toast.error('Ocorreu um erro ao resgatar informações da empresas, favor, tente novamente mais tarde', {
                position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch((erro) => {
            toast.error('Ocorreu um erro ao resgatar informações da empresas, favor, tente novamente mais tarde', {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
  }, [])

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (name === "cep") {
      const formattedValue = value.replace(/[.\s-]/g, "");
      setCepCompany(formattedValue);
      if (value.length === 10) {
        fetchCepData(formattedValue).then((data) => {
          if (data) {
            setCepData({
              bairro: data.bairro,
              cep: data.cep,
              complemento: data.complemento,
              localidade: data.localidade,
              logradouro: data.logradouro,
              uf: data.uf,
            });
          }
        });
      } else {
        setCepData({
          bairro: "",
          cep: "",
          complemento: "",
          localidade: "",
          logradouro: "",
          uf: "",
        });
      }
    } else {
      setCepData({ ...cepData, [name]: value });
    }
  }

  const fetchCepData = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (data.erro) {
        throw new Error("CEP não encontrado");
      }
      return data;
    } catch (error) {
      toast.error("Não foi possível recuperar os dados do CEP", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return null;
    }
  };

  function hancleCheckBox (e) {
    setIsBankSlipPayer(e.target.checked)
  }

  function submit(evento) {
    
    let switcher = false
    setIsLoading(true)
    const formData = new FormData(evento);
    let formProps = Object.fromEntries(formData);
    if (formProps.cep < 10) {
      switcher = true;
      setIsLoading(false);
      toast.error("O CEP deve conter exatamente 8 dígitos.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    let dataForSubmit = {
      cnpj: formProps.cnpj,
      social_reason: formProps.social_reason,
      domain: formProps.domain,
      responsible: formProps.responsible,
      site: formProps.site,
      number: formProps.number
        .replace(/[.]/g, "")
        .replace(/[-]/g, "")
        .replace(/[/]/g, "")
        .replace(/[(]/g, "")
        .replace(/[)]/g, "")
        .replace(/\s/g, ""),
      fantasy_name: formProps.fantasy_name,
      plan: planoID,
      is_bank_slip_payer: isBankSlipPayer,
      address: {
        compliment: formProps.complemento,
        bairro: cepData.bairro,
        cep: formProps.cep,
        cidade: cepData.localidade,
        logradouro: cepData.logradouro,
        uf: cepData.uf,
        numero: formProps.numero,
      },
    };

    Object.keys(dataForSubmit).forEach((item) => {
      if (
        dataForSubmit[item] === null ||
        dataForSubmit[item] === 0 ||
        dataForSubmit[item] === undefined ||
        dataForSubmit[item] === ""
      ) {
        if (item !== "site" && item !== "domain" && item !== "compliment") {
          switcher = true;
          setIsLoading(false);
          toast.error(
            "Verifique se todos os campos necessários estão preenchidos"
          );
        }
      }
    });

    if(switcher === false){
      if(mode === true) {
        api
          .put(`/company/update/${id}/`, dataForSubmit)
          .then((response) => {
            setIsLoading(false)
            if(response?.status === 200) {
              toast.success("Cliente alterado com sucesso", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/customers");
            } else {
              toast.error("Ocorreu um erro ao alterar o cliente", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            toast.error("Ocorreu um erro ao alterar o cliente", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        api
          .post("/company/create/", dataForSubmit, "application/json")
          .then((response) => {
            setIsLoading(false);
            if (response?.status === 201) {
              toast.success("Cliente criado com sucesso", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/customers");
            } else {
              toast.error("Ocorreu um erro ao criar a empresa", {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.error(response.response.data.detail.detail, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            toast.error("Ocorreu um erro ao criar a empresa", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  }

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: `${colors.primary}`,
          opacity: 1,
          border: 0,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <Title>
        <p>
          {mode === true ? "Alterar Cadastro de Cliente" : "Cadastrar Cliente"}
        </p>
        <Link to="/customers">
          <SafeButton variant="primary">Voltar</SafeButton>
        </Link>
      </Title>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submit(e.target);
          }}
        >
          <Row style={{ marginBottom: "3rem" }}>
            <InputContainer>
              <p>Razão social:</p>
              <Input
                name="social_reason"
                placeholder="Razão Social"
                defaultValue={companyData?.social_reason}
                required
              />
            </InputContainer>
            <InputContainer className="terciary-container">
              <p>Nome fantasia:</p>
              <Input
                name="fantasy_name"
                placeholder="Nome Fantasia"
                defaultValue={companyData?.fantasy_name}
                required
              />
            </InputContainer>
            <InputContainer className="small-container">
              <p>CNPJ: </p>
              <InputMaskNormal
                mask="99.999.999/9999-99"
                maskChar=""
                name="cnpj"
                placeholder="CNPJ"
                required
                value={cnpjCompany}
                onChange={(e) => {
                  let data = e.target.value;
                  setCnpjCompany(
                    data
                      .replace(/[.]/g, "")
                      .replace(/[-]/g, "")
                      .replace(/[/]/g, "")
                  );
                }}
              />
            </InputContainer>
            <InputContainer className="terciary-container">
              <p>Plano:</p>
              <SelectPicker
                name="plan"
                style={{ width: "100%" }}
                value={planoID}
                data={planoData}
                onSelect={(value) => setPlanoID(value)}
                onClean={() => setPlanoID()}
                id="planoPicker"
                required
              />
            </InputContainer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Pagar via boleto</label>
              <IOSSwitch checked={isBankSlipPayer} onChange={hancleCheckBox} />
            </div>
            <InputContainer className="small-container">
              <p>CEP:</p>
              <InputMaskNormal
                mask="99.999-999"
                maskChar=""
                placeholder="CEP"
                required
                name="cep"
                value={cepCompany}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <p>Logradouro:</p>
              <Input
                name="logradouro"
                disabled
                placeholder="Logradouro"
                onChange={handleInputChange}
                defaultValue={companyData?.address.logradouro}
                value={cepData.logradouro}
              />
            </InputContainer>
            <InputContainer>
              <p>Número:</p>
              <Input
                name="numero"
                placeholder="Número"
                type="number"
                defaultValue={companyData?.address.numero}
              />
            </InputContainer>
            <InputContainer>
              <p>Complemento:</p>
              <Input
                name="complemento"
                placeholder="Complemento"
                onChange={handleInputChange}
                defaultValue={companyData?.compliment}
              />
            </InputContainer>
            <InputContainer>
              <p>Bairro:</p>
              <Input
                name="bairro"
                required
                placeholder="Bairro"
                defaultValue={companyData?.address.bairro}
                onChange={handleInputChange}
                value={cepData.bairro}
              />
            </InputContainer>
            <InputContainer>
              <p>Localidade:</p>
              <Input
                name="localidade"
                disabled
                placeholder="Localidade"
                onChange={handleInputChange}
                defaultValue={companyData?.address.cidade}
                value={cepData.localidade}
              />
            </InputContainer>
            <InputContainer>
              <p>UF:</p>
              <Input
                name="uf"
                placeholder="UF"
                disabled
                onChange={handleInputChange}
                style={{ width: "100%", textTransform: "uppercase" }}
                defaultValue={companyData?.address.uf}
                value={cepData.uf}
              />
            </InputContainer>
            <InputContainer className="half-container">
              <p>Domínio:</p>
              <Input
                name="domain"
                placeholder="Domínio (email)"
                defaultValue={companyData?.domain}
              />
            </InputContainer>
            <InputContainer className="half-container">
              <p>Telefone:</p>
              <InputMaskNormal
                mask="(99) 9 9999-9999"
                maskChar=""
                name="number"
                required
                value={telCompany}
                placeholder="Telefone"
                onChange={(e) => {
                  let data = e.target.value;
                  setTelCompany(
                    data
                      .replace(/[.]/g, "")
                      .replace(/[-]/g, "")
                      .replace(/[/]/g, "")
                      .replace(/[(]/g, "")
                      .replace(/[)]/g, "")
                  );
                }}
              />
            </InputContainer>
            <InputContainer className="half-container">
              <p>E-mail do responsável:</p>
              <Input
                type="email"
                name="responsible"
                required
                placeholder="Email do responsável"
                defaultValue={
                  companyData?.responsible === null
                    ? ""
                    : companyData?.responsible
                }
              />
            </InputContainer>

            <InputContainer className="half-container">
              <p>Site:</p>
              <Input
                name="site"
                placeholder="Site"
                defaultValue={companyData?.site}
              />
            </InputContainer>
            {mode ? (
              <div
                style={{
                  height: "62.85px",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: width[0] > 993 ? "center" : "flex-start",
                }}
              >
                <div style={{ width: "250px" }}>
                  <p>Saldo: </p>
                  <div
                    style={{
                      color: "white",
                      height: "40px",
                      backgroundColor: "#616161",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "250px",
                      borderRadius: "8px",
                    }}
                  >
                    {companyData?.company_balance?.balance}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Row>

          <div className="buttonsContainer">
            <Link to="/customers" style={{ textDecoration: "none" }}>
              <SafeButton variant="secondary">Cancelar</SafeButton>
            </Link>
            <SafeButton variant="primary" type="submit" loading={isLoading}>
              {mode === true ? "Alterar Cadastro" : "Cadastrar"}
            </SafeButton>
          </div>
        </Form>
      </Container>
    </>
  );
}